<template>
    <div v-if="$isMobile()">
        <!-- BANNER -->
        <div>
            <!-- <b-carousel id="carousel-1" :interval="4000" indicators >
                <b-carousel-slide v-for="item in banners" :img-src="item.banner_picture"></b-carousel-slide>
            </b-carousel> -->
            <swiper :options="swiperOptionsBanner" class="w-100">
                <swiper-slide v-for="item in banners" :key="index" class="rounded swiper-shadow">
                    <img style="object-fit: cover;height: 300px;" class="rounded w-100" :src="item.banner_picture" />
                </swiper-slide>
            </swiper>
        </div>
        <!-- END BANNER -->

        <!-- CATEGORIES -->
        <div class="d-flex align-items-center justify-content-center flex-wrap px-2 my-2 mt-3" style="gap:10px 15px">
            <router-link to="/shelter" class="d-flex flex-column align-items-center">
                <div class="rounded-circle d-flex" style="background-color: #FFAD32;width: 55px;height:55px;">
                    <img src="@/static/img_assets/shelter.png" class="w-100 m-auto" style="max-width: 30px;" />
                </div>
                <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Shelter</div>
            </router-link>
            <div class="d-flex flex-column align-items-center">
                <div class="rounded-circle d-flex" style="background-color: #686569;width: 55px;height:55px;">
                    <img src="@/static/img_assets/pet-shop.png" class="w-100 m-auto" style="max-width: 30px;" />
                </div>
                <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Shop</div>
            </div>

            <router-link to="/homepage/services" class="d-flex flex-column align-items-center">
                <div class="rounded-circle d-flex" style="background-color: #FFAD32;width: 55px;height:55px;">
                    <img src="@/static/img_assets/hotel.png" class="w-100 m-auto" style="max-width: 30px;" />
                </div>
                <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Services</div>
            </router-link>
            <router-link to="/vet/home" class="d-flex flex-column align-items-center">
                <div class="rounded-circle d-flex" style="background-color: #FFAD32;width: 55px;height:55px;">
                    <img src="@/static/img_assets/vet.png" class="w-100 m-auto" style="max-width: 30px;" />
                </div>
                <div class="text-center font-weight-bolder h4 text-white" style="margin-top:.5em">Vet</div>
            </router-link>
        </div>
        <!-- END CATEGORIES -->

        <!-- LEARN MORE -->
        <div class="px-2 mb-2 mt-3">
            <swiper :options="swiperOptions" class="w-100">
                <swiper-slide v-for="(data, index) in ads" :key="index" class="rounded swiper-shadow">
                    <img style="height:200px;object-fit: cover;" class="rounded w-100" :src="data.ads_picture" />
                </swiper-slide>
                <div slot="button-next" class="swiper-button-next d-flex"><i
                        class="m-auto bx bx-chevron-right text-dark m-auto" style="font-size:28px"></i></div>
                <div slot="button-prev" class="swiper-button-prev d-flex"><i
                        class="m-auto bx bx-chevron-left text-dark m-auto" style="font-size:28px"></i></div>
            </swiper>
        </div>
        <!-- END LEARN MORE -->

    </div>
    <div v-else>
        <div class="container-lg">
            <div class="my-4">
                <swiper :options="swiperOptionsBanner" class="w-100">
                    <swiper-slide v-for="(item, index) in ads" :key="index" class="swiper-shadow">
                        <img style="object-fit: cover;height: 600px;border-radius: 20px;" class="w-100"
                            :src="item.ads_picture" />
                    </swiper-slide>
                </swiper>
            </div>
        </div>

        <div class="d-flex py-5 position-relative" style="background:#69BCDE">

            <img src="@/static/dog-left-right.png" class="position-absolute w-100"
                style="max-width:250px;bottom:120px;opacity:.5" />
            <img src="@/static/dog-right-left.png" class="position-absolute w-100"
                style="max-width:250px;right:0;top: 120px;opacity:.5" />

            <div class="m-auto">
                <div class="d-flex flex-wrap" style="gap:30px">
                    <router-link to="/shelter" class="d-flex flex-column align-items-center">
                        <div class="d-flex"
                            style="background-color: #FFAD32;width: 250px;aspect-ratio: 1/1;border-radius:20px">
                            <img src="@/static/img_assets/shelter.png" class="w-100 m-auto" style="max-width: 100px;" />
                        </div>
                        <div class="text-center font-weight-bolder h2 text-white mb-0" style="margin-top:.5em">Shelter
                        </div>
                    </router-link>
                    <router-link to="/vet/home" class="d-flex flex-column align-items-center">
                        <div class=" d-flex"
                            style="background-color: #FFAD32;width: 250px;aspect-ratio: 1/1;border-radius:20px">
                            <img src="@/static/img_assets/vet.png" class="w-100 m-auto" style="max-width: 100px;" />
                        </div>
                        <div class="text-center font-weight-bolder h2 text-white mb-0" style="margin-top:.5em">Vet</div>
                    </router-link>
                    <div class="d-flex flex-column align-items-center">
                        <div class="d-flex"
                            style="background-color: #686569;width: 250px;aspect-ratio: 1/1;border-radius:20px">
                            <img src="@/static/img_assets/pet-shop.png" class="w-100 m-auto"
                                style="max-width: 100px;" />
                        </div>
                        <div class="text-center font-weight-bolder h2 text-white mb-0" style="margin-top:.5em">Pet Shop
                        </div>
                    </div>
                    <router-link to="/training" class="d-flex flex-column align-items-center">
                        <div class=" d-flex"
                            style="background-color: #FFAD32;width: 250px;aspect-ratio: 1/1;border-radius:20px">
                            <img src="@/static/img_assets/trainer.png" class="w-100 m-auto" style="max-width: 100px;" />
                        </div>
                        <div class="text-center font-weight-bolder h2 text-white mb-0" style="margin-top:.5em">Trainer
                        </div>
                    </router-link>
                </div>

                <div class="d-flex flex-wrap mt-2" style="gap:30px">
                    <div class="d-flex flex-column align-items-center">
                        <div class="d-flex"
                            style="background-color: #686569;width: 250px;aspect-ratio: 1/1;border-radius:20px">
                            <img src="@/static/img_assets/groomer.png" class="w-100 m-auto" style="max-width: 100px;" />
                        </div>
                        <div class="text-center font-weight-bolder h2 text-white mb-0" style="margin-top:.5em">Groomer
                        </div>
                    </div>
                    <router-link to="/pet-sitting" class="d-flex flex-column align-items-center">
                        <div class=" d-flex"
                            style="background-color: #FFAD32;width: 250px;aspect-ratio: 1/1;border-radius:20px">
                            <img src="@/static/img_assets/walker.png" class="w-100 m-auto" style="max-width: 100px;" />
                        </div>
                        <div class="text-center font-weight-bolder h2 text-white mb-0" style="margin-top:.5em">Walker
                        </div>
                    </router-link>
                    <div class="d-flex flex-column align-items-center">
                        <div class="d-flex"
                            style="background-color: #686569;width: 250px;aspect-ratio: 1/1;border-radius:20px">
                            <img src="@/static/img_assets/taxi.png" class="w-100 m-auto" style="max-width: 100px;" />
                        </div>
                        <div class="text-center font-weight-bolder h2 text-white mb-0" style="margin-top:.5em">Taxi
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                        <div class="d-flex"
                            style="background-color: #686569;width: 250px;aspect-ratio: 1/1;border-radius:20px">
                            <img src="@/static/img_assets/hotel.png" class="w-100 m-auto" style="max-width: 100px;" />
                        </div>
                        <div class="text-center font-weight-bolder h2 text-white mb-0" style="margin-top:.5em">Hotel
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-lg py-5" id="news-section">
            <News />
        </div>

        <!-- style="background-color: #72B5D1;border-radius: 20px;" class="p-3 mx-2" -->
        <div class="position-relative container-lg">
            <img src="@/static/img_assets/image.png" class="position-absolute w-100 h-100" />
            <div class="ml-2 px-1 py-4 position-relative" style="z-index: 1;">
                <div class="h1 text-center text-white mb-3">Notice Boards</div>
                <div>
                    <div class="h1 text-white">Adoption Center</div>
                    <hr />
                    <div class="notice-boards" ref="adoption" style="gap:30px;">
                        <div v-for="item in adoption_list">
                            <div  class="p-1"
                                style="border-radius: 10px;border:2px solid #FFAD32;width:400px;background-color: rgba(0,0,0,.1);">
                                <div class="d-flex align-items-center py-1 px-1" style="gap:10px 20px">
                                    <div>
                                        <img :src="item.sha_picture ? item.sha_picture : default_img" class="rounded-circle"
                                            style="height:100px;aspect-ratio:1/1;background: white;" />
                                    </div>
                                    <div>
                                        <h3 class="text-white h5 mb-0">{{item.sha_name}}</h3>
                                        <h3 class="text-white h4 text-truncate" style="margin: .2em 0 !important;">
                                            {{item.sha_species}}
                                        </h3>
                                        <h3 class="text-white h5">{{item.sha_age}} Months, {{item.sha_gender}}</h3>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-center" style="gap:10px">
                                    <router-link :to="`/shelter/adopt/detail/${item.sha_id}`" class="btn px-1" style="
                                        border-radius: 15px;
                                        border: 2px solid #FFAD32;
                                        background: #005071;
                                        color: white;
                                    ">
                                        Know More
                                    </router-link> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <div @click="scrollLeft('adoption')" class="d-flex align-items-center h2 text-white" style="gap:10px;cursor: pointer;">
                            <i class="ti ti-chevron-left" style="font-size: 40px;"></i>
                            <div>Previous</div>
                        </div>
                        <div @click="scrollRight('adoption')" class="d-flex align-items-center h2 text-white" style="gap:10px;cursor: pointer;">
                            <div>Next</div>
                            <i class="ti ti-chevron-right" style="font-size: 40px;"></i>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <div class="h1 text-white">Volunteering</div>
                    <hr />
                    <div class="notice-boards" ref="volunteer" style="gap:30px;">
                        <div v-for="item in volunteer_list">
                            <div class="p-1"
                                style="border-radius: 10px;border:2px solid #FFAD32;width:400px !important;background-color: rgba(0,0,0,.1);"
                                >
                                <img v-if="item.shv_picture" class="w-100 rounded mb-1"
                                    style="aspect-ratio:4/1;object-fit: cover;background:white" :src="item.shv_picture" />

                                <div class="d-flex align-items-center" style="gap:10px 20px">
                                    <div class="h3 text-white mb-0">{{ item.shv_title }}, {{ item.shv_cause }}</div>
                                </div>

                                <div class="text-white h5" style="margin-top: .5em;">
                                    {{ moment(item.shv_date).format('dddd, DD MMMM YYYY') }}, {{ item.shv_time }}
                                </div>
                                <div class="text-white h5" style="margin-top: .5em;">{{ item.shv_location }}</div>

                                <div class="text-white h5 font-weight-bolder" style="margin-top: 1em;">Details: </div>

                                <p class="text-white" style="margin-top: .5em;">
                                    {{ item.shv_details }}
                                </p>

                                <div class="d-flex align-items-center justify-content-center" style="gap:10px">
                                    <router-link :to="`/shelter/volunteer/${item.shelter_id}`" class="btn px-1" style="
                                        border-radius: 15px;
                                        border: 2px solid #FFAD32;
                                        background: #005071;
                                        color: white;
                                    ">
                                        Know More
                                    </router-link> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <div @click="scrollLeft('volunteer')" class="d-flex align-items-center h2 text-white" style="gap:10px;cursor: pointer;">
                            <i class="ti ti-chevron-left" style="font-size: 40px;"></i>
                            <div>Previous</div>
                        </div>
                        <div @click="scrollRight('volunteer')" class="d-flex align-items-center h2 text-white" style="gap:10px;cursor: pointer;">
                            <div>Next</div>
                            <i class="ti ti-chevron-right" style="font-size: 40px;"></i>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <div class="h1 text-white">Donation</div>
                    <hr />
                    <div class="notice-boards" ref="donation" style="gap:30px;">
                        <div v-for="item in donation_list">
                            <div class="p-1" style="border-radius: 10px;border:2px solid #FFAD32;width: 400px;">
                                <img v-if="item.shd_picture" class="w-100 rounded mb-1" style="max-height: 100px;object-fit: cover;background-color: white;"
                                    :src="item.shd_picture" />
                                
                                <div class="d-flex align-items-center justify-content-between" style="gap:10px">
                                    <div class="h3 text-white mb-0">{{ item.shd_title }}</div> 
                                </div>

                                <div class="d-flex align-items-center" style="gap:10px">
                                    <div class="my-1 progress progress-bar-warning border flex-fill">
                                        <div class="progress-bar " role="progressbar" aria-valuenow="65" aria-valuemin="65"
                                            aria-valuemax="100" :style="`width: ${((item.shd_raised ? item.shd_raised : 0) / item.shd_fund_pledge) * 100}%`"></div>
                                    </div>
                                    <h5 class="mb-0 text-white">{{((item.shd_raised ? item.shd_raised : 0) / item.shd_fund_pledge) * 100}}%</h5>
                                </div>

                                <div class="row" style="gap:10px 0">
                                    <div class="col-6">
                                        <div class="text-white h5 mb-0">Pledge: $ $ {{item.shd_fund_pledge}},00</div>
                                    </div>
                                    <div class="col-6">
                                        <div class="text-white h5 mb-0">Raised: $ {{(item.shd_raised ? item.shd_raised : 0)}},00</div>
                                    </div>
                                    <div class="col-6">
                                        <div class="text-white h5 mb-0">Donators: {{item.donators.length}}</div>
                                    </div>
                                </div>

                                <div class="text-white h5 font-weight-bolder" style="margin-top: 1em;">Details: </div>

                                <p class="text-white" style="margin-top: .5em;">
                                    {{ item.shd_details }}
                                </p>

                                <div class="d-flex align-items-center justify-content-center" style="gap:10px">
                                    <router-link :to="`/shelter/donate/${item.shelter_id}`" class="btn px-1" style="
                                        border-radius: 15px;
                                        border: 2px solid #FFAD32;
                                        background: #005071;
                                        color: white;
                                    ">
                                        Know More
                                    </router-link> 
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <div @click="scrollLeft('donation')" class="d-flex align-items-center h2 text-white" style="gap:10px;cursor: pointer;">
                            <i class="ti ti-chevron-left" style="font-size: 40px;"></i>
                            <div>Previous</div>
                        </div>
                        <div @click="scrollRight('donation')" class="d-flex align-items-center h2 text-white" style="gap:10px;cursor: pointer;">
                            <div>Next</div>
                            <i class="ti ti-chevron-right" style="font-size: 40px;"></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="container-lg py-3 position-relative" style="z-index: 1;">
            <div class="d-flex flex-column my-3 align-items-center justify-content-center" style="gap:10px">
                <img src="@/static/logo - Copy.png" style="max-width:300px" />
                <div class="fw-bolder text-white display-3 mb-0">Be a Part Of Our Packs</div>
                <div class="text-white h3">Pet Goes Social</div>
            </div>
        </div>
    </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import { BCarousel, BCarouselSlide } from 'bootstrap-vue'
import moment from 'moment'

import store from '@/store'
import News from './component/news.vue';

export default {
    components: {
        Swiper, SwiperSlide,
        BCarousel, BCarouselSlide, News,
    },
    computed: {
        banners() {
            return store.state.banners.BANNERS || []
        },
        ads() {
            return store.state.banners.ADS || []
        },
        adoption_list(){
            return store.state.shelter.ADOPTION_LIST
        },
        donation_list(){
            return store.state.shelter.DONATION_LIST
        },
        volunteer_list(){
            return store.state.shelter.VOLUNTEER_LIST
        },
    },
    methods: {
        scrollLeft(item) {
            let scrollContainer = this.$refs.adoption;
            if(item == 'volunteer'){
                scrollContainer = this.$refs.volunteer;
            }
            else if(item == 'donation'){
                scrollContainer = this.$refs.donation;
            }
            scrollContainer.scrollBy({ left: -400, behavior: 'smooth' });
        },
        scrollRight(item) { 
            let scrollContainer = this.$refs.adoption;
            if(item == 'volunteer'){
                scrollContainer = this.$refs.volunteer;
            }
            else if(item == 'donation'){
                scrollContainer = this.$refs.donation;
            } 
            scrollContainer.scrollBy({ left: 400, behavior: 'smooth' });
        },
    },
    mounted() {
        store.dispatch('banners/GetBanners')
        if(!this.$isMobile()){
            store.dispatch('shelter/GetAdoptionList')
            store.dispatch('shelter/GetDonationList')
            store.dispatch('shelter/GetVolunteerList')
        }
        store.dispatch('banners/GetAds')
    },
    data() {
        return {
            learn_more_banner: [
                { url: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-cover-template-design-7fbcea6de5ed45af0716f61106314548_screen.jpg?ts=1622085870' },
                { url: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-banner-design-template-4c29604da3e3601ec0a5d464e2cbeb4b_screen.jpg?ts=1621925071' }
            ],
            swiperOptions: {
                slidesPerView: 1,
                spaceBetween: 10,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true, // Allows pagination dots to be clickable
                },
                autoplay: {
                    delay: 3000,
                },
            },
            swiperOptionsBanner: {
                slidesPerView: 1,
                spaceBetween: 10,
                autoplay: {
                    delay: 5000,
                },
            },
            moment, 
        }
    }
}

</script>

<style scoped>
.notice-boards {
    display: flex;
    overflow-x: scroll; /* Allows horizontal scrolling */
    overflow-y: hidden; /* Prevents vertical scrolling if unnecessary */
    scrollbar-width: none; /* Hides scrollbar in Firefox */
    -ms-overflow-style: none; /* Hides scrollbar in Internet Explorer and Edge */
}

.notice-boards::-webkit-scrollbar {
    display: none; /* Hides scrollbar in Chrome, Safari, and newer versions of Edge */
}
</style>

<style>
.input-group.search span {
    border-right: unset;
    border-color: #FFAD32;
    border-radius: 10px 0 0 10px;
    font-size: 24px;
    color: #FFAD32;
    background-color: transparent;
}

.input-group.search input {
    margin-left: 0;
    padding-left: 0;
    border-left: unset;
    border-radius: 0 10px 10px 0;
    border-color: #FFAD32;
    height: 40px;
    color: white;
    background-color: transparent !important;
}

.swiper-button-prev,
.swiper-button-next {
    --swiper-theme-color: transparent;
    background: #ffffff;
    box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0 !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    content: unset !important;
}
</style>